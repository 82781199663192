
import Vue from "vue";
import store from "@/store";

interface LangItem {
  icon: any;
  text: string;
  value: string;
}

export default Vue.extend({
  name: "LangSwitcher",

  props: {
    showText: {
      required: false,
      type: Boolean,
      default: false
    },
    darkIcon: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    show: false as boolean,
    items: [
      {
        icon: require("@/assets/images/locales/ro.svg"),
        text: "Română",
        value: "ro"
      },
      {
        icon: require("@/assets/images/locales/ru.svg"),
        text: "Русский",
        value: "ru"
      },
      {
        icon: require("@/assets/images/locales/en.svg"),
        text: "English",
        value: "en"
      }
    ] as Array<LangItem>
  }),

  computed: {
    lang: {
      set({ value }: LangItem) {
        store.dispatch("localization/set", value);
        const currentUrl = location.href.split("/");
        const langPosition = 3;

        currentUrl[langPosition] = value;
        location.href = currentUrl.join("/");
      },
      get(): string {
        return store.getters["localization/getCurrent"];
      }
    },
    icon() {
      return this.items.find(item => item.value === this.lang);
    }
  },

  methods: {
    toggle(): void {
      this.show = !this.show;
    }
  }
});
