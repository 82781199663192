
import Vue from "vue";

export default Vue.extend({
  name: "Preloader",

  computed: {
    show(): boolean {
      return this.$store.getters["preloader/show"];
    }
  }
});
