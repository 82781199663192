var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"min-width":"350","nudge-top":"-5","nudge-right":"-184","offset-y":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{staticClass:"z-index-4",attrs:{"color":"primary","top":""}},[_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"40","color":"#B1B6D4"}},'v-avatar',attrs,false),on),[(_vm.user && _vm.user.avatar)?_c('v-img',{attrs:{"src":_vm.getUserAvatar()}}):_c('span',{staticClass:"primary--text font-weight-medium title"},[_vm._v(" "+_vm._s(_vm.parseUsername())+" ")])],1)],1)]}}])},[_c('v-card',{staticClass:"align-center"},[_c('div',{staticClass:"pa-3 d-flex"},[_c('v-avatar',{attrs:{"size":"44","color":"#B1B6D4"}},[(_vm.user && _vm.user.avatar)?_c('v-img',{attrs:{"src":_vm.getUserAvatar()}}):_c('span',{staticClass:"primary--text font-weight-medium title"},[_vm._v(" "+_vm._s(_vm.parseUsername())+" ")])],1),_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"name-text"},[_vm._v(" "+_vm._s(_vm.user && _vm.user.name)+" ")]),_c('div',{staticClass:"caption light_secondary--text"},[_vm._v(" "+_vm._s(_vm.user && _vm.user.email)+" ")])])],1),(_vm.$vuetify.breakpoint.xs)?_c('v-divider'):_vm._e(),_c('div',{staticClass:"current-role"},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-menu',{attrs:{"bottom":"","offset-y":"","nudge-bottom":"20"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"px-3 role",attrs:{"text":""}},[_c('v-badge',{attrs:{"color":_vm.badgeColor,"dot":"","transition":"slide-x-transition"}},[_c('v-icon',{attrs:{"color":"rgba(0, 0, 0, 0.54)"}},[_vm._v(" mdi-account-convert ")])],1),_c('span',{staticClass:"role-name"},[_vm._v(" "+_vm._s(_vm.currentRole && _vm.currentRole.name)+" ")])],1)],1)]}}],null,false,1482605218)},[_c('v-card',[_c('v-list',{attrs:{"flat":""}},[_c('v-list-item-group',{attrs:{"value":_vm.currentRole,"color":"primary"}},_vm._l((_vm.roles),function(item,i){return _c('v-list-item',{key:i,staticClass:"px-4",attrs:{"value":item.value,"dense":""},on:{"click":function($event){return _vm.changeRole(item)}}},[_c('v-list-item-icon',{staticClass:"mx-1"},[_c('v-icon',{attrs:{"dense":"","color":item.color}},[_vm._v("mdi-circle")])],1),_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)}),1)],1)],1)],1):_vm._e()],1),_c('v-divider'),(this.$vuetify.breakpoint.xs)?_c('div',{staticClass:"online-users"},[_c('users-online',{attrs:{"show-text":""}})],1):_vm._e(),(this.$vuetify.breakpoint.xs)?_c('v-divider'):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"px-3",class:{ light_primary2: hover },attrs:{"dense":"","link":"","to":"/profile"}},[_c('v-list-item-icon',[_c('v-icon',{class:{ 'primary--text': hover }},[_vm._v("mdi-account")])],1),_c('v-list-item-content',{staticClass:"caption"},[_c('div',{staticClass:"list-item-text text-left",class:{ 'primary--text': hover }},[_vm._v(" "+_vm._s(_vm.$t("menu.profile"))+" ")])])],1)]}}])}),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"px-3",class:{ light_primary2: hover },attrs:{"dense":""},on:{"click":_vm.openThemeSettings}},[_c('v-list-item-icon',[_c('v-icon',{class:{ 'primary--text': hover }},[_vm._v("mdi-cog")])],1),_c('v-list-item-content',{staticClass:"caption"},[_c('div',{staticClass:"list-item-text text-left",class:{ 'primary--text': hover }},[_vm._v(" "+_vm._s(_vm.$t("menu.settings"))+" ")])])],1)]}}])}),(_vm.$vuetify.breakpoint.xs)?_c('v-divider'):_vm._e(),_c('div',{staticClass:"lang-switcher"},[(_vm.$vuetify.breakpoint.xs)?_c('lang-switcher',{attrs:{"show-text":""}}):_vm._e()],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-divider'):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"currency"},[_c('v-icon',{staticClass:"cash-icon",attrs:{"size":"28"}},[_vm._v("mdi-cash")]),_c('currency')],1):_vm._e(),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{directives:[{name:"confirm",rawName:"v-confirm",value:({
            title: _vm.$t('menu.exit'),
            message: _vm.$t('global_alert.exit'),
            callback: function () { return _vm.logout(); }
          }),expression:"{\n            title: $t('menu.exit'),\n            message: $t('global_alert.exit'),\n            callback: () => logout()\n          }"}],staticClass:"px-3",class:{ light_primary2: hover },attrs:{"dense":""}},[_c('v-list-item-icon',[_c('v-icon',{class:{ 'primary--text': hover },attrs:{"color":"#b6b6b6"}},[_vm._v("mdi-exit-to-app")])],1),_c('v-list-item-content',{staticClass:"caption"},[_c('div',{staticClass:"list-item-text text-left",class:{ 'primary--text': hover }},[_vm._v(" "+_vm._s(_vm.$t("menu.exit"))+" ")])])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }