
import Vue from "vue";

export default Vue.extend({
  name: "Currency",

  data: () => ({
    currencyValues: {
      EUR: require("@/assets/images/locales/eu.svg"),
      USD: require("@/assets/images/locales/us.svg"),
      RUB: require("@/assets/images/locales/ru.svg"),
      RON: require("@/assets/images/locales/ro.svg"),
      UAH: require("@/assets/images/locales/ua.svg")
    },
    currencies: [] as Array<any>,
    timer: null as any
  }),

  computed: {
    hasAccessToken() {
      return this.$store.getters["authentication/hasAccessToken"];
    }
  },

  async mounted() {
    if (this.hasAccessToken) {
      await this.loadData();
      this.timer = setInterval(this.loadData, 300000);
    }
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {
    async loadData() {
      try {
        const credentials = await this.$store.getters[
          "authentication/credentials"
        ];

        if (credentials.token) {
          this.currencies = await this.$API.exchangeRates().getDataCurrent();
        } else {
          clearInterval(this.timer);
        }
      } catch (e) {
        if (e.message) {
          await this.$store.dispatch("alert/showError", e.message);
        }
      }
    }
  }
});
