
import Vue from "vue";

interface ColorsInterface {
  dark?: any;
  light?: any;
}

export default Vue.extend({
  name: "ThemeSettings",

  data: () => ({
    mini: false as boolean,
    colors: ["#d67879", "#fe6e00", "#444444", "#23B7C9", "#0064A8"],
    isDark: !!JSON.parse(localStorage.getItem("isDark") as string) as boolean,
    primaryColor: "#23B7C9",
    iconColor: "#23B7C9"
  }),

  watch: {
    isToggled: {
      immediate: true,
      handler() {
        this.mini = this.isToggled;
      }
    }
  },

  computed: {
    expandedSideBar: {
      get(): boolean {
        return this.$store.getters["navigation/expanded"];
      },
      set(): void {
        this.$store.dispatch("navigation/toggle");
      }
    },
    isToggled() {
      return this.$store.getters["navigation/mini"];
    }
  },

  beforeMount() {
    this.changeTheme(this.isDark);
    this.loadLocaleColor();
  },

  methods: {
    loadLocaleColor() {
      const colors = JSON.parse(localStorage.getItem("colors") as string) || {};
      this.$vuetify.theme.themes.light = {
        ...this.$vuetify.theme.themes.light,
        ...colors.light
      };
      this.$vuetify.theme.themes.dark = {
        ...this.$vuetify.theme.themes.dark,
        ...colors.dark
      };
    },
    toggle(): void {
      this.mini = !this.mini;
    },
    changeColor(primary: string): void {
      this.$vuetify.theme.themes.light.primary = primary;
      this.$vuetify.theme.themes.dark.primary = primary;

      if (this.$vuetify.theme.themes.light["side-bar-color"] === "#ffffff") {
        this.$vuetify.theme.themes.light[
          "side-bar-selected-text-color"
        ] = primary;
      }

      this.writeToLocalStorage({
        dark: { primary },
        light: {
          primary,
          "side-bar-selected-text-color": this.$vuetify.theme.themes.light[
            "side-bar-selected-text-color"
          ]
        }
      });
    },
    writeToLocalStorage(colors: ColorsInterface) {
      const savedColors = {
        dark: {},
        light: {}
      };

      const localeColors =
        JSON.parse(localStorage.getItem("colors") as string) || savedColors;

      localStorage.setItem(
        "colors",
        JSON.stringify({
          dark: {
            ...localeColors.dark,
            ...colors.dark
          },
          light: {
            ...localeColors.light,
            ...colors.light
          }
        })
      );
    },
    changeTheme(value: boolean): void {
      setTimeout(() => {
        this.isDark = value;
      }, 350);

      (this.$vuetify.theme as any).isDark = value;
      this.$vuetify.theme.themes.light = {
        ...this.$vuetify.theme.themes.light
      };
      this.$vuetify.theme.themes.dark = {
        ...this.$vuetify.theme.themes.dark
      };
      localStorage.setItem("isDark", value.toString());
    },
    setDarkMenu(): void {
      const colors = {
        "side-bar-text-color": "#81848B",
        "side-bar-header-color": "#666666",
        "side-bar-color": "#262B33",
        "side-bar-hovered-color": "#393c41",
        "side-bar-selected-text-color": "#F5F6F8",
        "side-bar-accent-color": "#1b1f24"
      };
      this.$vuetify.theme.themes.light = {
        ...this.$vuetify.theme.themes.light,
        ...colors
      };
      this.writeToLocalStorage({
        light: colors
      });
    },
    setLightMenu(): void {
      const primary = this.$vuetify.theme.themes.light.primary;
      const colors = {
        "side-bar-text-color": "#3D4051",
        "side-bar-header-color": "#ffffff",
        "side-bar-color": "#ffffff",
        "side-bar-hovered-color": "#ffffff",
        "side-bar-selected-text-color": primary,
        "side-bar-accent-color": "#eeeeee"
      };

      this.$vuetify.theme.themes.light = {
        ...this.$vuetify.theme.themes.light,
        ...colors
      };

      this.writeToLocalStorage({
        light: colors
      });
    },
    setDefaultMenu(): void {
      const colors = {
        "side-bar-text-color": "#ffffff",
        "side-bar-header-color": "#ffffff",
        "side-bar-color": "#337AB7",
        "side-bar-hovered-color": "#ffffff",
        "side-bar-selected-text-color": "#eeeeee",
        "side-bar-accent-color": "#749fc3"
      };

      this.$vuetify.theme.themes.light = {
        ...this.$vuetify.theme.themes.light,
        ...colors
      };

      this.writeToLocalStorage({
        light: colors
      });
    },
    closeThemeSettings() {
      this.$store.dispatch("navigation/toggleThemes");
      this.$store.dispatch("navigation/set");
    }
  }
});
