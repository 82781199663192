
import Vue from "vue";

export default Vue.extend({
  name: "UsersOnline",

  props: {
    showText: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    items: [] as Array<any>,
    loadInterval: null as any,
    loading: false as boolean,
    menu: false as boolean,
    queryString: "" as string,
    hide: true as boolean,
    colors: {
      admin: "red",
      guest: "blue",
      chairman: "orange",
      vice_president: "pink",
      department_head: "green",
      specialist: "brown",
      direction_head: "#3f8dc3",
      branch_head: "aqua",
      bookkeeper_chief: "chartreuse",
      bookkeeper: "crimson",
      expert_chief: "darkturquoise",
      expert: "deepskyblue",
      specialist_ata: "khaki",
      specialist_aaij: "lightgreen",
      beneficiary: "mediumslateblue",
      default: "grey"
    } as any
  }),

  computed: {
    user() {
      return this.$store.getters["user/info"];
    },
    filteredUsers() {
      return this.items.filter((item: any) =>
        item.name.toLowerCase().includes(this.queryString.toLowerCase())
      );
    },
    currentRole() {
      return this.$store.getters["user/currentRole"];
    }
  },

  async mounted() {
    if (this.currentRole && this.currentRole.alias === "admin") {
      await this.loadData();

      if (!this.hide) {
        this.loadInterval = setInterval(async () => {
          await this.loadData();
        }, 60000);
      }
    }
  },

  methods: {
    async loadData() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      try {
        this.items = await this.$API.users().getOnline();
        this.hide = false;
      } catch (e) {
        await Promise.resolve();
      }
      this.loading = false;
    },
    openChat(item: any) {
      this.menu = false;
      this.$store.dispatch("chat/set", item.room_id);
    },
    async setFocus(value: boolean) {
      clearInterval(this.loadInterval);

      if (value) {
        await this.loadData();
        this.loadInterval = setInterval(async () => {
          await this.loadData();
        }, 10000);
        setTimeout(() => {
          (this.$refs.search as Vue).focus();
        }, 300);
      } else {
        this.loadInterval = setInterval(async () => {
          await this.loadData();
        }, 60000);
      }
    }
  },

  beforeDestroy() {
    clearInterval(this.loadInterval);
  }
});
