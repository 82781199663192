
import Vue from "vue";
import NavBar from "@/components/navigation/NavBar.vue";
import Alert from "@/components/dialogs/Alert.vue";
import Preloader from "@/components/dialogs/Preloader.vue";
import SideBar from "@/components/navigation/SideBar.vue";
import ThemeSettings from "@/components/settings/ThemeSettings.vue";

export default Vue.extend({
  name: "Default",

  components: { ThemeSettings, SideBar, Preloader, Alert, NavBar }
});
