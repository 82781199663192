
import Vue from "vue";
import LangSwitcher from "@/components/settings/LangSwitcher.vue";
import Currency from "@/components/viewers/Currency.vue";
import UsersOnline from "@/components/navigation/UsersOnline.vue";

export default Vue.extend({
  name: "Profile",

  components: { LangSwitcher, Currency, UsersOnline },

  props: {
    currentRole: {
      default: () => ({} as any),
      required: true
    },
    user: {
      default: () => ({} as any),
      required: true
    },
    colors: {
      type: Object,
      default: () => ({} as any),
      required: true
    },
    roles: {
      type: Array,
      default: () => [] as Array<any>,
      required: true
    }
  },

  computed: {
    badgeColor(): any {
      if (this.currentRole) {
        return this.colors[this.currentRole.alias];
      }
      return this.colors.default;
    }
  },

  methods: {
    changeRole(value: any): void {
      this.$store.dispatch("user/changeCurrentRole", value);
    },
    getUserAvatar() {
      return this.user.avatar.url;
    },
    async logout() {
      try {
        await this.$store.dispatch("authentication/logout");
        await this.$router.push("/auth/login");
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    parseUsername() {
      return this.user?.name
        .split(" ")
        .map(([item]: any) => item)
        .slice(0, 2)
        .join("")
        .toUpperCase();
    },
    openThemeSettings() {
      this.$store.dispatch("navigation/toggleThemes");
      this.$store.dispatch("navigation/set");
    }
  }
});
