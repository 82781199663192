
import Vue from "vue";
import LangSwitcher from "@/components/settings/LangSwitcher.vue";
import startCase from "lodash/fp/startCase";
import Profile from "@/components/navigation/Profile.vue";
import Notifications from "@/components/navigation/Notifications.vue";
import Currency from "@/components/viewers/Currency.vue";

export default Vue.extend({
  name: "NavBar",

  components: { Currency, Notifications, Profile, LangSwitcher },

  data: () => ({
    startCase,
    closeOnContentClick: true as boolean,
    showSearch: false as boolean,
    show: false as boolean,
    colors: {
      admin: "red",
      guest: "blue",
      chairman: "orange",
      vice_president: "pink",
      department_head: "green",
      specialist: "brown",
      direction_head: "#3f8dc3",
      branch_head: "aqua",
      bookkeeper_chief: "chartreuse",
      bookkeeper: "crimson",
      expert_chief: "darkturquoise",
      expert: "deepskyblue",
      specialist_ata: "khaki",
      specialist_aaij: "lightgreen",
      beneficiary: "mediumslateblue",
      default: "grey"
    } as any,
    roles: [] as Array<any>,
    currentRole: null as any
  }),

  computed: {
    badgeColor() {
      if (this.currentRole) {
        return this.colors[this.currentRole.alias];
      }
      return this.colors.default;
    },
    moduleName() {
      const localizedTitle = this.$t(
        `${this.$route?.meta?.module}.title`
      ) as string;

      if (localizedTitle.includes(".")) {
        return this.startCase(this.$route?.meta?.module);
      }
      return localizedTitle;
    },
    user() {
      return this.$store.getters["user/info"];
    },
    credentials() {
      return this.$store.getters["authentication/credentials"];
    }
  },

  async beforeMount(): Promise<void> {
    if (!this.user) {
      await this.getUser();
    }

    await this.getCurrentRole();
  },

  methods: {
    async getUser() {
      try {
        if (!this.credentials?.user) {
          return;
        }

        const {
          user: { id }
        } = this.credentials;

        const response = await this.$API.users().checkStatus(id);
        await this.$store.dispatch("user/set", response);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async getCurrentRole() {
      let role = localStorage.getItem("localRole") as any;

      if (role === "undefined") {
        role = null;
      }

      if (
        role &&
        this.user &&
        this.user.roles.some(
          (item: any) => item.alias === JSON.parse(role)?.alias
        )
      ) {
        const roleIndex = this.user.roles.findIndex(
          (item: any) => item.alias === JSON.parse(role)?.alias
        );

        this.currentRole = this.user.roles[roleIndex];
      } else if (this.user?.roles?.length) {
        this.currentRole = this.user.roles[0];
      }
    },
    toggleSideBar(): void {
      this.$store.dispatch("navigation/toggle");
    },
    changeRole(value: any): void {
      this.$store.dispatch("user/changeCurrentRole", value);
    },
    toggleSearch(): void {
      this.showSearch = !this.showSearch;

      if (this.showSearch) {
        this.$nextTick(() => {
          (this.$refs.search as any).focus();
        });
      }
    }
  }
});
